.waitlist {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.waitlist .navigation-left {
  align-items: center;
  align-self: stretch;
  display: flex;
  position: relative;
  width: 100%;
}

.waitlist .logo {
  height: 19px;
  object-fit: cover;
  position: relative;
  width: 90px;
}

.waitlist .navigation-right {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 18px;
  height: 32px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}

.waitlist .text-wrapper {
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.waitlist .main-cta {
  height: 23.25px;
  position: relative;
  transition: all 0.2s ease;
  width: 78px;
}

.waitlist .overlap-group {
  height: 23px;
  position: relative;
  width: 76px;
}

.waitlist .rectangle {
  background-color: #ffffff;
  border-radius: 5px;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 76px;
}

.waitlist .div {
  color: #7086ff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  height: 23px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 76px;
}

.waitlist .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 297px;
}

.waitlist .img {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 267px;
}

.waitlist .navigation-right-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 39px;
  justify-content: flex-end;
  position: relative;
}

.waitlist .text-wrapper-2 {
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.waitlist .group-wrapper {
  height: 51px;
  position: relative;
  transition: all 0.2s ease;
  width: 144px;
}

.waitlist .group {
  height: 51px;
  transition: all 0.2s ease;
  width: 146px;
}

.waitlist .div-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  height: 51px;
  position: relative;
  width: 144px;
}

.waitlist .text-wrapper-3 {
  color: #7086ff;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
  width: 144px;
}

.waitlist .frame-2 {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(196.56, 219.94, 255) 100%);
  display: flex;
  position: relative;
  width: 100%;
}

.waitlist .frame-3 {
  align-self: stretch;
  display: flex;
  position: relative;
}

.waitlist .frame-4 {
  align-self: stretch;
  position: relative;
}

.waitlist .text-wrapper-4 {
  align-self: stretch;
  color: #393939;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.waitlist .p {
  align-self: stretch;
  color: #000000;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.4px;
  position: relative;
}

.waitlist .frame-5 {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
}

.waitlist .enter-email-address {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #3f3b3b3b;
  color: #0000005c;
  flex: 1;
  flex-grow: 1;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  letter-spacing: 0;
  line-height: normal;
  padding: 10px 10px 10px 16px;
  position: relative;
}

.waitlist .frame-6 {
  align-items: center;
  background-color: #0459f0;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 20px;
  height: 50px;
  justify-content: center;
  position: relative;
}

.waitlist .text-wrapper-5 {
  color: #ffffff;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.waitlist .vector {
  height: 590.45px;
  margin-left: -36.5px;
  margin-right: -24.01px;
  position: relative;
  width: 613.19px;
}

.waitlist .waiting {
  height: 514.67px;
  margin-left: -376px;
  position: relative;
  width: 386px;
}

.waitlist .frame-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.waitlist .rectangle-2 {
  position: relative;
}

.waitlist .text-wrapper-6 {
  color: #393939;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 55px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 334px;
}

.waitlist .text-wrapper-7 {
  color: #373737;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.9px;
  position: relative;
  width: 411px;
}

.waitlist .frame-8 {
  position: relative;
}

.waitlist .vector-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  margin-bottom: -24.05px;
  margin-left: -25.61px;
  margin-top: -25.59px;
  position: relative;
}

.waitlist .waiting-2 {
  align-self: stretch;
  margin-left: -263.77px;
  position: relative;
  width: 319.38px;
}

.waitlist .frame-wrapper {
  height: 50px;
  position: relative;
  width: 310px;
}

.waitlist .enter-email-address-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #3f3b3b3b;
  color: #0000005c;
  flex: 0 0 auto;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  letter-spacing: 0;
  line-height: normal;
  padding: 10px 10px 10px 16px;
  position: relative;
  white-space: nowrap;
  width: 310px;
}

.waitlist .frame-9 {
  align-items: center;
  background-color: #0459f0;
  display: flex;
  gap: 20px;
  height: 50px;
  justify-content: center;
  position: relative;
  width: 118px;
}

.waitlist {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.waitlist .navigation-left {
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.waitlist .logo {
  object-fit: cover;
  width: 90px;
  height: 19px;
  position: relative;
}

.waitlist .navigation-right {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  height: 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.waitlist .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.waitlist .main-cta {
  width: 78px;
  height: 23.25px;
  transition: all .2s;
  position: relative;
}

.waitlist .overlap-group {
  width: 76px;
  height: 23px;
  position: relative;
}

.waitlist .rectangle {
  background-color: #fff;
  border-radius: 5px;
  width: 76px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 0;
}

.waitlist .div {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 76px;
  height: 23px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.waitlist .frame {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 297px;
  display: flex;
  position: relative;
}

.waitlist .img {
  object-fit: cover;
  width: 267px;
  height: 57px;
  position: relative;
}

.waitlist .navigation-right-2 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 39px;
  display: flex;
  position: relative;
}

.waitlist .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.waitlist .group-wrapper {
  width: 144px;
  height: 51px;
  transition: all .2s;
  position: relative;
}

.waitlist .group {
  width: 146px;
  height: 51px;
  transition: all .2s;
}

.waitlist .div-wrapper {
  background-color: #fff;
  border-radius: 10px;
  width: 144px;
  height: 51px;
  position: relative;
}

.waitlist .text-wrapper-3 {
  color: #7086ff;
  letter-spacing: 0;
  text-align: center;
  width: 144px;
  height: 24px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 0;
}

.waitlist .frame-2 {
  background: linear-gradient(#fff 0%, #c5dcff 100%);
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.waitlist .frame-3 {
  align-self: stretch;
  display: flex;
  position: relative;
}

.waitlist .frame-4 {
  align-self: stretch;
  position: relative;
}

.waitlist .text-wrapper-4 {
  color: #393939;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.waitlist .p {
  color: #000;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.4px;
  position: relative;
}

.waitlist .frame-5 {
  flex: none;
  align-self: stretch;
  position: relative;
}

.waitlist .enter-email-address {
  color: #0000005c;
  letter-spacing: 0;
  background-color: #fff;
  border: 1px solid #3f3b3b3b;
  flex: 1;
  height: 50px;
  padding: 10px 10px 10px 16px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.waitlist .frame-6 {
  background-color: #0459f0;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 50px;
  display: flex;
  position: relative;
}

.waitlist .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.waitlist .vector {
  width: 613.19px;
  height: 590.45px;
  margin-left: -36.5px;
  margin-right: -24.01px;
  position: relative;
}

.waitlist .waiting {
  width: 386px;
  height: 514.67px;
  margin-left: -376px;
  position: relative;
}

.waitlist .frame-7 {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.waitlist .rectangle-2 {
  position: relative;
}

.waitlist .text-wrapper-6 {
  color: #393939;
  letter-spacing: 0;
  width: 334px;
  margin-top: -1px;
  font-family: Product Sans-Bold, Helvetica;
  font-size: 55px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.waitlist .text-wrapper-7 {
  color: #373737;
  letter-spacing: 0;
  width: 411px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.9px;
  position: relative;
}

.waitlist .frame-8 {
  position: relative;
}

.waitlist .vector-2 {
  flex: 1;
  align-self: stretch;
  margin-top: -25.59px;
  margin-bottom: -24.05px;
  margin-left: -25.61px;
  position: relative;
}

.waitlist .waiting-2 {
  align-self: stretch;
  width: 319.38px;
  margin-left: -263.77px;
  position: relative;
}

.waitlist .frame-wrapper {
  width: 310px;
  height: 50px;
  position: relative;
}

.waitlist .enter-email-address-wrapper {
  color: #0000005c;
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #3f3b3b3b;
  flex: none;
  width: 310px;
  height: 50px;
  padding: 10px 10px 10px 16px;
  font-family: Product Sans-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.waitlist .frame-9 {
  background-color: #0459f0;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 118px;
  height: 50px;
  display: flex;
  position: relative;
}

/*# sourceMappingURL=index.21d69241.css.map */
